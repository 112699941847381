// module.exports = {
//   baseImgAPIDomain: "https://tnb-api-cbm.vertikaliti.com:5001",
//   baseAPIDomain: "https://tnb-api-cbm.vertikaliti.com/api/v1",
//   baseAdminAPIDomain: "https://tnb-api-cbm.vertikaliti.com/admin/v1",
//   reportBase: "https://tnb-api-cbm.vertikaliti.com/",
//   emailAPIDomain: "https://tnb-api-cbm.vertikaliti.com:9000",
//   EMAIL_ENGINE_TOKEN:
//     "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjEydG5iMjM0IiwiaWF0IjoxNTcwMTY5MDkxfQ.hpnaMgB7wI8dROvo-Sl9CknM_bKz7gYwBG5lJ9rVB7k"
// };

module.exports = {
  baseImgAPIDomain: "https://gridv3-api.vertikaliti.com:5001",
  baseAPIDomain: 'https://gridv3-api.vertikaliti.com/api/v1',
  baseAdminAPIDomain: 'https://gridv3-api.vertikaliti.com/admin/v1',

  // baseAPIDomain: "https://staging-api.grid.vertikaliti.com/api/v1",
  // baseAdminAPIDomain: "https://staging-api.grid.vertikaliti.com/admin/v1",

  // baseAPIDomain: "http://localhost:3002/api/v1",
  // baseAdminAPIDomain: "http://localhost:3002/admin/v1",

  // baseAPIDomain: 'http://localhost:5123/api/v1',
  // baseAdminAPIDomain: 'http://localhost:5123/admin/v1',

  reportBase: "https://grid-api.vertikaliti.com/",
  emailAPIDomain: "https://grid-api.vertikaliti.com:9000",
  EMAIL_ENGINE_TOKEN:
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjEydG5iMjM0IiwiaWF0IjoxNTcwMTY5MDkxfQ.hpnaMgB7wI8dROvo-Sl9CknM_bKz7gYwBG5lJ9rVB7k"

   ,

  };
